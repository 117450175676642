<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" :rules="rules" :model="form" v-loading="loading" label-position="right" label-suffix=":" label-width="100px" size="large" element-loading-text="数据加载中" class="dialog_box">
                <el-form-item label="商城名称" prop="name">
                    <el-input type="text" auto-complete="off" v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="商城简介" prop="desc">
                    <el-input type="textarea" :rows="4" auto-complete="off" v-model="form.desc" placeholder="请输入简介"></el-input>
                </el-form-item>
                <el-form-item label="商城logo" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入logo链接"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <div class="avatar">
                        <img v-if="form.logo" :src="form.logo" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-form-item>
                <el-form-item label="商城店招" prop="shopsign">
                    <el-input v-model="form.shopsign" placeholder="请输入店招链接"></el-input>
                    <el-button @click.prevent="choiceImg('shopsign')">选择图片</el-button>
                    <div class="avatar">
                        <img v-if="form.shopsign" :src="form.shopsign" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-form-item>
                <el-form-item label="商城二维码" prop="qcode">
                    <el-input v-model="form.qcode" placeholder="请输入二维码链接"></el-input>
                    <el-button @click.prevent="choiceImg('qcode')">选择图片</el-button>
                    <div class="avatar">
                        <img v-if="form.qcode" :src="form.qcode" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-form-item>
                <el-form-item label="客服电话" prop="cphone">
                    <el-input type="text" auto-complete="off" v-model="form.cphone" placeholder="请输入客服电话"></el-input>
                </el-form-item>
                <!-- <el-form-item label="退货联系人" prop="refund_name">
                    <el-input type="text" auto-complete="off" v-model="storeInfo.refund_name" placeholder="请输入退货联系人">
                    </el-input>
                </el-form-item>
                <el-form-item label="退货电话" prop="refund_phone">
                    <el-input type="text" auto-complete="off" v-model="storeInfo.refund_phone" placeholder="请输入退货电话">
                    </el-input>
                </el-form-item>
                <el-form-item label="退货地址" prop="refund_address">
                    <el-input type="text" auto-complete="off" v-model="storeInfo.refund_address" placeholder="请输入退货地址">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否营业" prop="allow_edit_info">
                    <el-switch on-text off-text v-model="storeInfo.allow_edit_info"></el-switch>
                    <div class="tips">停业后，用户不可在商城消费及储值，可正常查询信息</div>
                </el-form-item>-->
            </el-form>
            <div>
                <el-button type="primary" @click="submit">保 存</el-button>
            </div>
        </div>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import Picture from '@/components/Picture';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
import { mapState, mapActions } from "vuex";
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        request,
        Picture
    },
    data() {
        return {
            ImgVisible: false,
            text: 'image',
            menuList: ['设置', '商城信息'],
            loading: false,
            header: {},
            rules: {
                name: [{ required: true, message: '商城名称不能为空', trigger: 'blur' }],
                desc: [{ required: true, message: '商城简介不能为空', trigger: 'blur' }],
                logo: [{ required: true, message: '商城Logo不能为空', trigger: ['blur'] }],
                img: [{ required: true, message: '商城店招不能为空', trigger: ['blur', 'change'] }],
                cphone: [{ required: true, message: '客服电话不能为空', trigger: 'blur' }]

            },
            value: ['1', '3'],
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';

    },
    computed: {
        ...mapState({
            form: state => state.storeInfo,
        }),

    },
    methods: {
        handleChange(value) {
            this.form.shop_business = value[0];
            this.form.cat2 = value[1];
        },

        // 保存店铺信息
        submit() {
            let _this = this
            this.$refs.form.validate(valid => {
                if (valid) {
                    request.post('/system/set/edit', this.form).then(ret => {
                        if (ret.code == 1) {
                            this.$message.success('编辑成功');
                        } else {
                            this.$message.error(ret.msg);
                        }
                    });

                } else {
                    this.$message.error('请补全信息');
                    return false;
                }
            });
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
    }
};
</script>

<style scoped>
.avatar {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    width: 100px;
    height: 100px;
    background-color: #dddddd;
}
.avatar img {
    width: 100%;
    height: 100%;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box >>> .el-textarea {
    width: 30%;
}
.dialog_box .up {
    margin-top: 20px;
    margin-bottom: -10px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>